import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import config from 'devextreme/core/config';

if (environment.production) {
  enableProdMode();
}

config({ licenseKey: environment.devextremeLicenseKey });

async function bootstrap() {
  await platformBrowserDynamic().bootstrapModule(AppModule);
}

bootstrap().catch(err => console.log(err));

defineCustomElements(window);
